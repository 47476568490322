import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Accessories = ({ data, location }) => {
  const { t , i18n } = useTranslation()

  const brand = (i18n.language === "tr" ? data.site.siteMetadata.titleTr : data.site.siteMetadata.titleEn) || `tbant`

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": t("accessoriesTitle"),
    "image": "https://tbantsan.com/static/dcc6ad77ec14512d1d3c5d17be0e43fa/63d25/accessories.avif",
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": "0",
      "availability": "https://schema.org/InStock",
      "url": data.site.siteMetadata.siteUrl + location.pathname,
      "priceValidUntil": ""
    },
    "description": t("propertiesDescription"),
    "brand": brand,
  }

  const seo = {
    title: t("metaAccessoriesTitle"),
    description: t("metaAccessoriesDescription"),
    keywords: t("metaAccessoriesKeywords"),
    schema: schema
  }

  return (
    <Layout className="bg-lightGray" navbarTheme="dark">
      <Seo {...seo} />
      <div className="bg-lightGray">
        <div className="max-w-7xl mx-auto px-6 md:px-10 py-10">
          <div className="md:flex justify-between">
            <div>
              <h1 className="text-3xl md:text-5xl font-semibold text-darkBlue">
                {t("accessoriesTitle")}
              </h1>
              <h2 className="text-darkBlue font-normal text-lg max-w-2xl">
                {t("accessoriesDescription")}
              </h2>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-6 md:px-10">
          <div className="overflow-hidden">
            <StaticImage
              src="../images/accessories.png"
              formats={["auto", "webp", "avif"]}
              alt="accesories"
              className="transition hover:scale-110"
            />
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="text-darkBlue">
            <div className="md:flex flex-wrap justify-between">
              <div className="w-full md:w-1/2 px-6 md:px-10 py-10 my-6">
                <p className="text-blue text-xl font-bold mb-6">
                  {t("propertiesTitle")}
                </p>
                <div className="md:py-0 whitespace-pre-line text-lg">
                  {t("propertiesDescription")}
                </div>
              </div>

              <div className="w-full md:w-1/2 text-darkBlue px-6 md:px-10 py-10 my-6">
                <p className="text-blue text-xl font-bold mb-6">
                  {t("mainUsesTitle")}
                </p>
                <div className="md:py-0 text-lg">
                  <ul>
                    <li className="list-disc list-inside">
                      {t("mainUsesDescription1")}
                    </li>
                    <li className="list-disc list-inside">
                      {t("mainUsesDescription2")}
                    </li>
                    <li className="list-disc list-inside">
                      {t("mainUsesDescription3")}
                    </li>
                    <li className="list-disc list-inside">
                      {t("mainUsesDescription4")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex flex-wrap justify-between py-6 text-darkBlue mb-48">
            <div className="w-full md:w-1/2 px-6 md:px-10 py-10 mb-8">
              <div className="overflow-hidden">
                <StaticImage
                  src="../images/side-plate.png"
                  formats={["auto", "webp", "avif"]}
                  alt={t("sidePlateTitle")}
                  className="transition hover:scale-110"
                />
              </div>
              <p className="font-bold mb-3 mt-6 text-lg">
                {t("sidePlateTitle")}
              </p>
              <p className="whitespace-pre-line text-lg">
                {t("sidePlateDescription")}
              </p>
            </div>
            <div className="w-full md:w-1/2 px-6 md:px-10 py-10 mb-8">
              <div className="overflow-hidden">
                <StaticImage
                  src="../images/brackets-and-profiles.png"
                  formats={["auto", "webp", "avif"]}
                  alt={t("bracketsAndProfilesTitle")}
                  className="transition hover:scale-110"
                />
              </div>
              <p className="font-bold mb-3 mt-6 text-lg">
                {t("bracketsAndProfilesTitle")}
              </p>
              <p className="whitespace-pre-line text-lg">
                {t("bracketsAndProfilesDescription")}
              </p>
            </div>
            <div className="w-full md:w-1/2 px-6 md:px-10 py-10 mb-8">
              <div className="overflow-hidden">
                <StaticImage
                  src="../images/metal-and-perforated-sheets.png"
                  formats={["auto", "webp", "avif"]}
                  alt={t("metalAndPerforatedSheetsTitle")}
                  className="transition hover:scale-110"
                />
              </div>
              <p className="font-bold mb-3 mt-6 text-lg">
                {t("metalAndPerforatedSheetsTitle")}
              </p>
              <p className="whitespace-pre-line text-lg">
                {t("metalAndPerforatedSheetsDescription")}
              </p>
            </div>
            <div className="w-full md:w-1/2 px-6 md:px-10 py-10 mb-8">
              <div className="overflow-hidden">
                <StaticImage
                  src="../images/gears.png"
                  formats={["auto", "webp", "avif"]}
                  alt={t("gearsTitle")}
                  className="transition hover:scale-110"
                />
              </div>
              <p className="font-bold mb-3 mt-6 text-lg">
                {t("gearsTitle")}
              </p>
              <p className="whitespace-pre-line text-lg">
                {t("gearsDescription")}
              </p>
            </div>
            <div className="w-full md:w-1/2 px-6 md:px-10 py-10 mb-8">
              <div className="overflow-hidden">
                <StaticImage
                  src="../images/chains.png"
                  formats={["auto", "webp", "avif"]}
                  alt={t("chainsTitle")}
                  className="transition hover:scale-110"
                />
              </div>
              <p className="font-bold mb-3 mt-6 text-lg">
                {t("chainsTitle")}
              </p>
              <p className="whitespace-pre-line text-lg">
                {t("chainsDescription")}
              </p>
            </div>
            <div className="w-full md:w-1/2 px-6 md:px-10 py-10 mb-8">
              <div className="overflow-hidden">
                <StaticImage
                  src="../images/assembling-and-maintenance-repair-parts.png"
                  formats={["auto", "webp", "avif"]}
                  alt={t("assemblingAndMaintenanceRepairPartsTitle")}
                  className="transition hover:scale-110"
                />
              </div>
              <p className="font-bold mb-3 mt-6 text-lg">
                {t("assemblingAndMaintenanceRepairPartsTitle")}
              </p>
              <p className="whitespace-pre-line text-lg">
                {t("assemblingAndMaintenanceRepairPartsDescription")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Accessories

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTr
        titleEn
        siteUrl
      }
    }
  }
`